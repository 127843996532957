import * as React from 'react';
import cn from 'clsx';
import { getDataAttributes } from '@wix/editor-elements-common-utils';
import type { MenuItemProps } from '@wix/editor-elements-definitions';
import type { FC } from 'react';
import type { SubmenuPreviewMapperProps, SubmenuProps } from '../types';
import { useMenuItemContext } from '../../../common/menu/MenuItemContext';
import { isCurrentItem } from '../../../common/menu/getCurrentMenuItem';
import styles from './Submenu.scss';
import Link from '@wix/thunderbolt-elements/src/components/Link/viewer/Link';
import { TestIds } from './constants';
import {
  getItemDepthSelector,
  subItemDepthLevel,
  subSubItemDepthLevel,
} from '../../../common/menu';

type ItemListProps = SubmenuPreviewMapperProps & {
  items: Array<MenuItemProps>;
  currentItem?: MenuItemProps;
  isRootLevel?: boolean;
};

const ItemList: FC<ItemListProps> = ({
  items,
  currentItem,
  isRootLevel = true,
  previewStateMap,
}) => {
  return (
    <ul
      className={cn(isRootLevel ? styles.list : styles.subList)}
      style={
        {
          '--items-number': items.length,
        } as React.CSSProperties
      }
      onKeyDown={e => {
        if (e.key === 'Escape') {
          const target = e.target as HTMLElement;
          const parentListItem = target.closest('li') as HTMLElement;

          const parentNavigateTo = parentListItem.closest(
            getItemDepthSelector(0),
          );

          (parentNavigateTo?.firstChild as HTMLElement)?.focus();
        }
      }}
    >
      {items.map((item, i) => {
        const { items: subItems, link, label } = item;
        return (
          <li
            className={cn({ [styles.listItem]: !isRootLevel })}
            key={i}
            data-item-depth={
              // FYI: For submenu root level is sub item level
              isRootLevel ? subItemDepthLevel : subSubItemDepthLevel
            }
          >
            <Link
              {...link}
              className={cn(isRootLevel ? styles.item : styles.subItem, {
                [styles.selected]: isCurrentItem(item, currentItem),
              })}
              dataPreview={previewStateMap?.[isRootLevel ? 'item' : 'subItem']}
              onFocus={e => {
                e.stopPropagation();
              }}
            >
              <span
                className={isRootLevel ? styles.itemLabel : styles.subItemLabel}
                data-testid={
                  isRootLevel ? TestIds.itemLabel : TestIds.subItemLabel
                }
              >
                {label}
              </span>
            </Link>
            {subItems?.length && (
              <ItemList
                previewStateMap={previewStateMap}
                items={subItems}
                isRootLevel={false}
                currentItem={currentItem}
              />
            )}
          </li>
        );
      })}
    </ul>
  );
};

const Submenu: React.FC<SubmenuProps> = props => {
  const { id, previewStateMap } = props;
  const { item, currentItem } = useMenuItemContext();

  return (
    <div id={id} {...getDataAttributes(props)} className={cn(styles.root)}>
      <ItemList
        previewStateMap={previewStateMap}
        items={item.items || []}
        currentItem={currentItem}
      />
    </div>
  );
};

export default Submenu;
